import { defineStore } from "pinia";
import router from "../router/router.js";
import axios from "axios";

const CURRENT_USER = "auth.currentUser";

// Initialize the auth state from localStorage
const initialState = getSavedState(CURRENT_USER);

// Set default auth headers based on the initial state
setDefaultAuthHeaders(initialState);

export const useAuthStore = defineStore("auth", {
  state: () => ({
    auth: initialState,
  }),

  getters: {
    isAuthenticated: (state) => !!state.auth,
  },

  actions: {
    getUser() {
      return getSavedState(CURRENT_USER);
    },

    // LogIn(authDetails) {
    //   const tenantSubDomain = getTenantSubDomain();
    //   return axios
    //     .post("/auth-service/ui/auth", {
    //       ...authDetails,
    //       tenantSubDomain,
    //     })
    //     .then((response) => {
    //       if (response.data.statusCode === "200") {
    //         this.auth = {
    //           ...response.data,
    //           token: response.headers.authorization,
    //         };

    //         if (!this.auth.landingPage) {
    //           this.auth.landingPage = "/";
    //         }
    //         saveState(CURRENT_USER, this.auth);
    //         setDefaultAuthHeaders(this.auth);

    //         return this.auth;
    //       } else if (response.data.statusCode === "400") {
    //         throw new Error(response.data.statusMessage);
    //       }
    //     });
    // },

    LogIn(authDetails) {
      const tenantSubDomain = getTenantSubDomain();
      return axios
        .post("/auth-service/ui/auth", {
          ...authDetails, // Include username, password, otp, transactionId
          tenantSubDomain,
        })
        .then((response) => {
          // Handle successful login (statusCode 200)
          if (response.data.statusCode === "200") {
            this.auth = {
              ...response.data,
              token: response.headers.authorization,
            };
            // Ensure landing page is set
            if (!this.auth.landingPage) {
              this.auth.landingPage = "/";
            }
            // Save state and set default headers
            saveState(CURRENT_USER, this.auth);
            setDefaultAuthHeaders(this.auth);
            return this.auth;
          }
          // Handle two-factor authentication (statusCode 400 and twoFaEnabled)
          if (
            response.data.statusCode === "400" &&
            response.data.twoFaEnabled
          ) {
            return response.data;
          }
          // Handle other errors
          throw new Error(response.data.statusMessage);
        })
        .catch((error) => {
          throw new Error(error.message);
        });
    },

    logoutOntokenExpired() {
      this.logOut();
    },

    logOut() {
      axios.get("/auth-service/ui/logout");
      this.clearAuthState();
      localStorage.removeItem("modalActive"); // Clear modal state
    },

    resetPassword({ username, tenantSubDomain } = {}) {
      return axios
        .post("/auth-service/noauth/password/setNewPassword/generateOtp", {
          username,
          tenantSubDomain,
        })
        .then((response) => {
          const message = response.data;
          return message;
        });
    },

    clearAuthState() {
      setDefaultAuthHeaders(null);

      this.auth = null;
      saveState(CURRENT_USER, null);

      window.history.replaceState({}, "", "/login");
      router.replace("/login").catch(() => {});
    },
  },
});

function setDefaultAuthHeaders(currentUser) {
  axios.defaults.headers.common.Authorization =
    currentUser && currentUser.token ? `Bearer ${currentUser.token}` : "";
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}

function getSavedState(key) {
  const state = JSON.parse(window.localStorage.getItem(key)) || null;
  return state;
}

function getTenantSubDomain() {
  const host = window.location.host;
  const parts = host.split(".");
  const domainLength = 3;
  return parts.length >= domainLength && parts[0] !== "www"
    ? parts[0]
    : process.env.VUE_APP_SUB_DOMAIN;
}
